const resource = {
    en: {
        heading: 'Error 500',
        message: 'There was an unexpected server error. Please inform the owners of the website.',
        back_to_home: 'Back to home',
    },
    de: {
        heading: 'Fehler 500',
        message: 'Es kam zu einem unvorhergesehenen Serverfehler. Bitte benachrichtigen Sie die Betreiber der Webseite.',
        back_to_home: 'Zurück zur Homepage',
    }
}

export default resource;